// Next/previous controls
const plusSlides = (slideShowId, slidePosition) => {
  showSlides(
    slideShowId,
    (currentSlidePositions[slideShowId] += slidePosition)
  );
};

// Thumbnail image controls
const currentSlide = (slideShowId, slidePosition) => {
  showSlides(slideShowId, (currentSlidePositions[slideShowId] = slidePosition));
};

const showSlides = (slideShowId, slidePosition) => {
  let i;
  let slides = document.querySelectorAll(
    `[slideShowId="${slideShowId}"].mySlides`
  );

  if (slidePosition > slides.length) {
    currentSlidePositions[slideShowId] = 1;
  }
  if (slidePosition < 1) {
    currentSlidePositions[slideShowId] = slides.length;
  }
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }
  slides[currentSlidePositions[slideShowId] - 1].style.display = "block";
};

let currentSlidePositions = {};

const slideshows = document.querySelectorAll(`div.slideshow-container`);
for (slideshow of slideshows) {
  currentSlidePositions[slideshow.id] = 1;
  showSlides(slideshow.id, currentSlidePositions[slideshow.id]);
}

window.currentSlide = currentSlide;
window.plusSlides = plusSlides;
window.showSlides = showSlides;
